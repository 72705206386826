.feature.is-f1 {
  position: absolute;
  width: 28.433333%;
  top: 158px;
  z-index: 1;

  .feature {
    &__description {
      max-width: 280px;
    }
  }

  .feature__images {
    margin-bottom: 30px;

    img {
      width: 100%;
    }
  }

  .feature__circle {
    bottom: -14.4708423%;
    left: -16.33333333%;
  }
}


@include tablets {
  .feature.is-f1 {
    top: 0;
    width: calc(100% - #{2*$wrapperPaddingTablets});
    padding-left: 44.262295082%;
    padding-top: 73px;

    .feature {
      &__description {
        max-width: 380px;
      }
    }

    .feature__images {
      position: absolute;
      top: 0;
      left: 0;
      width: 44.540229885%;

      &__mobile1 {
        width: 60.967741935%;
      }

      &__mobile2 {
        width: 62.903225806%;
        left: 38.387096774%;
        bottom: 32px;
        top: auto;
      }
    }

    .feature__circle {
      left: 19.396551724%;
      top: 14px;
    }
  }
}

@include mobile {
  .feature.is-f1 {
    position: relative;
    top: auto;

    .feature {
      &__text {
        padding: 0;
        text-align: center;
      }

      &__description {
        margin: 0 auto;
      }

      &__images {
        position: relative;
        width: 50%;
        left: auto;
        top: auto;
        margin: 0 auto 8px;

        &__mobile1 {
          width: 60.2649006623%;
        }
      }

      &__circle {
        left: 50%;
        bottom: -29px;
        transform: translateX(-50%);
      }
    }
  }
}