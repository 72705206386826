.feature.is-f2 {
  width: 46.657929226%;//356px on 1440px
  max-width: 356px;
  position: relative;
  margin-top: 36px;

  .feature {
    &__circle {
      bottom: -7px;
      left: 42px;
    }

    &__images {
      min-height: 188px;
      position: relative;

      img {
        position: absolute;
      }

      &-circle {
        bottom: 21.808510638%;// 41px;
        width: 28.651685393%;// 102px;
      }

      &-chart {
        width: 76.685393258%;// 273px;
        left: 27.528089887%;//98px;
        bottom: -11.702127659%;// -22px;
      }
    }

    &__description {
      max-width: 280px;
    }
  }
}

@include tablets {
  .feature.is-f2 {
    max-width: 100%;
    width: 100%;
    padding-left: 3.00546448087%;//22px
    padding-right: 46.9945355191%;
    display: flex;
    flex-direction: column-reverse;

    .feature {
      &__text {
        max-width: 356px;
      }

      &__images {
        &-circle {
          width: 26.288659793%;
          bottom: auto;
          top: 23.936170212%;
        }

        &-chart {
          width: 70.360824742%;
          bottom: auto;
          top: 10.638297872%;
        }
      }

      &__circle {
        top: -8px;
        left: 8.46994535519%;
      }
    }
  }
}

@include mobile {
  .feature.is-f2 {
    max-width: 100%;
    width: 100%;
    padding: 0;

    .feature {
      &__images {
        width: 60%;
        min-width: 265px;
        min-height: 129px;
        margin: 0 auto 8px;

        &-circle {
          bottom: 34.8837209302%;
          top: auto;
        }

        &-chart {
          bottom: -22px;
          left: 28.7671232877%;
        }
      }

      &__text {
        text-align: center;
      }

      &__description {
        margin: 0 auto;
      }

      &__circle {
        left: 50%;
        transform: translateX(-50%);
        top: -15px;
        bottom: auto;
        right: auto;
      }
    }
  }
}