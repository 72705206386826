.footer {
  padding: 30px 0;
  position: relative;

  .wrapper {
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  .splashes {
    .is-pink {
      top: -494px;
      right: -236px;
    }
  }

  .language-selector {
    display: none;
  }

  .address {
    p {
      margin: 0;
    }
  }
}

@include mobile {
  .footer {
    .wrapper {
      flex-wrap: wrap;
      justify-content: space-between;
    }

    &__logo {
      order: 1;
    }

    &__nav {
      order: 3;
    }

    .language-selector {
      display: initial;
      order: 1;
    }

    .address {
      width: 100%;
      order: 3;
      margin-top: 10px;
    }
  }
}
