@import "./dots";
@import "./splashes";

.main-block {
  padding: 54px 0 134px;
}

.main {
  &__title {
    font-family: Bebas Neue, sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 52px;
    line-height: 56px;
    text-align: center;
    color: #FFFFFF;
    max-width: 552px;
    margin: 0 auto;

    &-secondary {
      text-align: center;
      font-size: 15px;
      line-height: 21px;
      color: #ffffff;
      margin-top: 0;
    }

    &-highlight {
      display: inline-block;
      position: relative;

      &:before {
        content: "";
        position: absolute;
        width: calc(100% + 12px);
        height: 100%;
        left: -6px;
        top: -4px;
        background: linear-gradient(90deg, #DC2B49 -2.57%, #4539D7 120%);
        z-index: -1;
      }
    }
  }

  &__play {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &__center {
    padding-top: 85px;
  }

  &__bottom {
    padding-top: 142px;
    padding-left: 35.3%;
  }

  &__present {
    display: flex;
    position: absolute;
    top: 18px;
    right: 12.777777777%;
    align-items: center;
    z-index: 2;

    &-text {
      max-width: 129px;
      text-align: right;
      padding-right: 8px;
      transition: all .3s ease;
    }
  }
}

.action-text {
  max-width: 86px;
  //margin-right: 30px;
  position: absolute;
  left: calc(50% - 140px);
}


@include tablets {
  .main-block {
    padding: 26px 0 62px;
  }

  .main {
    &__title-secondary {
      margin-bottom: 12px;
    }

    &__center {
      padding-top: 320px;
      position: relative;
    }

    &__play {
      position: relative;
      padding-right: 58px;

      .action-text {
        left: 50%;
        transform: translateX(-50%);
        top: calc(100% + 12px);
        padding-right: 58px;
        max-width: 144px;
      }
    }

    &__bottom {
      padding-left: 0;
      padding-top: 98px;
    }

    &__present {
      top: 808px;
    }
  }
}

@include mobile {
  .main-block {
    padding: 38px 0 206px;
  }

  .main {
    &__title {
      font-size: 34px;
      line-height: 100%;
    }

    &__center {
      display: flex;
      padding-top: 32px;
      flex-direction: column;
    }

    &__play {
      padding-top: 20px;
      padding-bottom: 70px;
      position: relative;

      .action-text {
        left: 50%;
        transform: translateX(-50%);
        top: calc(100% - 56px);
        max-width: 76px;
      }
    }

    &__bottom {
      padding: 53px 0 0;
    }

    &__present {
      top: auto;
      bottom: -139px;
      right: auto;
      left: 0;
      width: 100%;
      justify-content: center;

      .present.is-small {
        width: 86px;
        height: 86px;
        flex: 0 0 86px;

        .present__inner {
          width: 56px;
          height: 56px;

          svg {
            width: 20px;
            height: 20px;
          }
        }
      }
    }
  }
}

@media screen and (max-width: 1260px) and (min-width: 1024px) {
  .main__present {
    flex-direction: column;
    right: 9%;
  }
}
