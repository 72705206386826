.for-who-container {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
}

.for-who {
  width: calc(50% - 10px);
  padding: 21px 8px 34px 33px;
  color: #fff;

  &__icon {
    margin-bottom: 30px;
    width: 147px;
    height: 144px;
    background-repeat: no-repeat;
    background-position: center;
    background-size: 147px;
  }

  &.is-commerce {
    .for-who__icon {
      background-image: url("../images/for-who/commerce.svg");
    }
  }

  &.is-corporate {
    .for-who__icon {
      background-image: url("../images/for-who/corporate.svg");
    }

    .for-who__list li:before {
      background: linear-gradient(180deg, #FD792E 0%, #F39A27 59.6%);
    }
  }

  &__title {
    text-transform: uppercase;
    margin-bottom: 13px;
  }

  &__subtitle {
    font-style: normal;
    font-weight: 600;
    font-size: 15px;
    line-height: 150%;
    margin-bottom: 23px;
  }

  &__list {
    margin: 0;
    padding: 0;

    li {
      list-style: none;
      position: relative;
      padding-left: 24px;
      margin-bottom: 15px;

      span {
        font-size: 15px;
        font-weight: 700;
        line-height: 22.5px;
      }

      &:last-child {
       margin-bottom: 0;
      }


      &:before {
        content: "";
        display: block;
        width: 14px;
        height: 14px;
        border-radius: 14px;
        position: absolute;
        left: 0;
        top: 4px;
        background: linear-gradient(90deg, #DC2B49 0%, #4539D7 215.62%);
        box-shadow: 0px 0px 10px rgba(186, 46, 105, 0.3);
        z-index: 1;
      }

      &:after {
        content: "";
        display: block;
        width: 6px;
        height: 6px;
        border-radius: 6px;
        position: absolute;
        left: 4px;
        top: 8px;
        z-index: 2;
        background: #fff;
      }
    }
  }
}


@include tablets {
  .for-who-container {
    padding-top: 68px;
  }
}


@include mobile {
  .for-who {
    width: 100%;
    padding: 15px 30px 20px 20px;
    margin: 0;

    &__icon {
      margin-bottom: 20px;
    }

    &.is-commerce {
      margin-bottom: 10px;
    }
  }

  .for-who-container {
    flex-wrap: wrap;
  }
}