.feature.is-f3 {
  position: absolute;
  right: $wrapperPadding;
  top: 151px;
  width: 28.402777777%;
  z-index: 1;

  .feature {
    &__images {
      width: 100%;
      position: relative;
      margin-bottom: 35px;

      img {
        width: 100%;
      }
    }

    &__description {
      max-width: 280px;
    }

    &__text {
      padding-left: 16%;
    }

    &__circle {
      bottom: -16.08910891%;// -65px;
      left: 7.33496325%;//30px;
    }
  }
}

@include tablets {
  .feature.is-f3 {
    width: calc(100% + 9px);
    padding-left: 55.327868852%;
    padding-top: 196px;
    left: 0;
    top: 0;
    right: auto;

    .feature {
      &__text {
        padding-left: 0;
      }

      &__circle {
        left: 58.060109289%;
        top: auto;
        bottom: 22px;
      }
    }
  }
}


@include mobile {
  .feature.is-f3 {
    position: relative;
    top: auto;
    left: auto;
    right: auto;
    margin-top: 84px;

    .feature {
      &__images {
        width: 50%;
        margin: 0 auto 8px;
        text-align: center;
      }

      &__text {
        padding: 0;
        text-align: center;
      }

      &__description {
        margin: 0 auto;
      }

      &__circle {
        left: 50%;
        transform: translateX(-50%);
        top: -40px;
        bottom: auto;
      }
    }
  }
}