@import "./dots";

$featurePanelWidth: 400px;
$featureGap: 63px;

.organizer2-block {
  background: #1b2028;

  .wrapper {
    padding-top: 100px;
    padding-bottom: 69px;
    position: relative;
  }

  &__data {
    display: flex;
  }

  &__title {
    line-height: 50px;
    margin-bottom: 11px;
  }
}

.organizer2__features {
  position: relative;
}

.organizer2__feature {
  color: #ffffff;
  font-style: normal;
  margin-bottom: 14px;
  padding: 18px 20px 21px;
  border-radius: 10px;
  border: 1px solid rgba(0, 0, 0, 0);
  //position: relative;
  display: flex;
  width: $featurePanelWidth;
  cursor: pointer;
  transition: all 0.2s ease;

  &:last-child {
    margin-bottom: 0;
  }

  &:hover {
    border-color: #676a6f;
    //box-shadow: inset 15px 10px 50px rgba(255, 255, 255, 0.05);
  }

  &__title {
    font-weight: 600;
    font-size: 18px;
    line-height: 150%;
    margin-bottom: 15px;
  }

  &__image {
    display: none;
    position: absolute;
    left: #{$featurePanelWidth + $featureGap};
    top: 0;
    height: 100%;
    width: calc(100% - #{$featurePanelWidth + $featureGap});

    img {
      max-width: 100%;
      max-height: 100%;
      object-fit: contain;
      border-radius: 10px;
    }
  }

  &.is-active {
    background: linear-gradient(130.5deg, #282e37 4.23%, rgba(27, 32, 40, 0) 105.89%);
    box-shadow: inset 15px 10px 50px rgba(255, 255, 255, 0.05);
    border-color: #59595a;
    cursor: default;

    &:hover {
      text-decoration: none;
    }

    .organizer2__feature__image {
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
}

.organizer2__present {
  position: absolute;
  bottom: 0;
  right: 11.52%;
  transform: translateY(50%);
  z-index: 3;
}

.organizer2-block .splashes {
  .is-blue {
    top: 2px;
    right: -73px;
  }
}

@include touch {
  .organizer2-block {
    background: #1b2028;

    .wrapper {
      padding-top: 100px;
      padding-bottom: 69зч;
      position: relative;
    }

    &__title {
      line-height: 50px;
      margin-bottom: 25px;
    }
  }

  .organizer2__feature__image {
    img {
      border-radius: 5px;
    }
  }

  .organizer2__present {
    right: 22.6775956284%;
  }

  .organizer2__feature {
    &__title {
      font-size: 16px;
      line-height: 150%;
      margin-bottom: 10px;
    }
  }
}

@include mobile {
  .organizer2-block {
    margin-top: 0;
    background: none;

    .wrapper {
      padding-top: 60px;
      padding-bottom: 20px;
      position: relative;
    }

    &__title {
      font-size: 30px;
      line-height: 36px;
    }
  }

  .organizer2__feature {
    width: 100%;
    background: linear-gradient(122.72deg, #282e37 4.72%, rgba(27, 32, 40, 0) 118.13%);
    box-shadow: inset 15px 10px 50px rgba(255, 255, 255, 0.05);
    border-radius: 8px;
    border-color: #59595a;
    position: relative;
    flex-direction: column;

    &:after {
      content: "";
      display: block;
      width: 10px;
      height: 5px;
      background: url("../images/organizer2/arrow.svg") no-repeat center center;
      position: absolute;
      right: 20px;
      top: 28px;
      transition: all 0.3s ease;
      transform: translateZ(0);
    }

    &__title {
      margin: 0;
    }

    &__description {
      display: none;
    }

    &__image {
      position: static;
    }

    &.is-active {
      &:after {
        transform: rotate(180deg);
      }

      .organizer2__feature__title {
        margin-bottom: 15px;
      }

      .organizer2__feature__description {
        display: block;
        margin-bottom: 15px;
      }
      .organizer2__feature__image {
        width: 100%;
      }
    }
  }

  .organizer2__present {
    right: 9px;
    top: 81px;
    bottom: auto;
  }
}
