.feature {
  &__title {
    margin-bottom: 15px;
    text-transform: uppercase;
  }

  &__text {
    position: relative;
  }

  &__circle {
    width: 267px;
    height: 267px;
    border-radius: 267px;
    position: absolute;
    background: url("../images/main/circle_bg.png") no-repeat center center;
  }
}


@import "features/f1";
@import "features/f2";
@import "features/f3";


@include mobile {
  .main__center .feature {
    width: 100%;

    &__description {
      font-size: 12px;
      line-height: 140%;
    }

    &__title {
      margin-bottom: 8px;
    }
  }
}